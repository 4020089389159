import ReferenceCard from "./ReferenceCard";
import Slider from "./Slider";
import {useRef} from "react";
import AliceCarousel from "react-alice-carousel";
import {useRouter} from "next/router";
import CredentialsItem, {ICredentialsItem} from "./CredentialsItem";
import Link from 'next/link';


const Home = () => {
  const router = useRouter();
  const ref = useRef<AliceCarousel>(null);


    const credentials: ICredentialsItem[] = [
      {
        title: 'Wedding - Sprengelstraße',
        subtitle: 'Umbau und Trockenbau',
        text: 'Bei diesem Objekt wurde durch den Einsatz von Trockenbauelementen eine komplett neue Raumaufteilung ' +
          'geschaffen. Von den Sanitäranlagen über die Elektronik bis hin zu den Brandschutztüren und ' +
          'Fußbodenverlegearbeiten wurde fachmännisch eine moderne Gewerbeeinheit geschaffen.',
        photos: [
          '/credentials/1/1.png',
          '/credentials/1/2.png',
          '/credentials/1/3.png',
          '/credentials/1/4.png',
          '/credentials/1/5.png',
          '/credentials/1/6.png',
          '/credentials/1/7.png',
          '/credentials/1/8.png',
          '/credentials/1/9.png',
          '/credentials/1/10.png',
        ]
      },
      {
        title: 'Reinickendorf – Alt-Wittenau',
        subtitle: 'Fassadensanierung',
        text: 'Nach außen ist eine Fassade die Visitenkarte eines Hauses und sie schützt das Innere vor Witterung und ' +
          'Zerfall. Deshalb ist es wichtig bei der Fassadensanierung auf das Fachwissen von Experten zurückzugreifen. ' +
          'Wir beraten Sie, ob Ausbesserungsarbeiten genügen oder wie im Objekt in Alt-Wittenau die Fassade komplett ' +
          'saniert werden muss.',
        photos: [
          '/credentials/2/1.png',
          '/credentials/2/2.png',
          '/credentials/2/3.png',
        ]
      },
      {
        title: 'Charlottenburg - Rankestraße',
        subtitle: 'Badrenovierung',
        text: 'Ob Gästebad, ein Badezimmer für die ganze Familie oder Seniorengerecht – ein Badezimmer sollte nicht ' +
          'nur zweckmäßig sein. Wir beraten Sie über die vielseitigen Möglichkeiten und schaffen ein Ort zum ' +
          'Wohlfühlen. ',
        photos: [
          '/credentials/3/1.png',
          '/credentials/3/2.png',
          '/credentials/3/3.png',
        ]
      },
      {
        title: 'Wilmersdorf – Berliner Straße',
        subtitle: 'Komplettsanierung',
        text: 'Wir sind Spezialisten im Rahmen der Sanierung und Renovierung von Bestandsimmobilien und bieten gerne ' +
          'sowohl eine Komplettlösung als auch die Ausführung nur einzelner Leistungen an. In der Berliner Straße ' +
          'wurde ein in die Jahre gekommener Blumenladen in eine moderne Gewerbeeinheit umgebaut.',
        photos: [
          '/credentials/4/1.png',
          '/credentials/4/2.png',
          '/credentials/4/3.png',
          '/credentials/4/4.png',
          '/credentials/4/5.png',
          '/credentials/4/6.png',
          '/credentials/4/7.png',
          '/credentials/4/8.png',
          '/credentials/4/9.png',
          '/credentials/4/10.png',
          '/credentials/4/11.png',
          '/credentials/4/12.png',
          '/credentials/4/13.png',
          '/credentials/4/14.png',
        ]
      },
      {
        title: 'Charlottenburg',
        subtitle: 'Badrenovierung',
        text: 'Ob Gästebad, ein Badezimmer für die ganze Familie oder Seniorengerecht – ein Badezimmer sollte nicht ' +
          'nur zweckmäßig sein. Wir beraten Sie über die vielseitigen Möglichkeiten und schaffen ein Ort zum Wohlfühlen.',
        photos: []
      }
    ];

  const reference = [
    {
      image: "/credentials/Sternauto - Potsdam/0.jpg",
      name:  'Sternauto - Potsdam',
      text: ""
    },
    {
      image: "/credentials/V-ZUG - Kurfürstendamm/1.jpg",
      name: "V-ZUG Berlin",
      text: ""
    },
    {
      image: '/credentials/Einfamilienhaus - Hohen Neuendorf/1.jpg',
      name: 'Einfamilienhaus - Oranienburg',
      text: ''
    },
    {
      image: '/credentials/Café - Tegel/1.jpg',
      name: 'Darna Cafe - Tegel',
      text: ''
    },
    {
      image: "/credentials/Außenanlage/0.jpg",
      name: "Außenanlage - Moabit",
      text: ""
    },
    {
      image: '/credentials/Fassade/1.jpg',
      name: 'Fassade',
      text: ''
    },
    {
      image: "/credentials/Bäder/1.jpg",
      name: "Bäder",
      text: ""
    },
    {
      image: '/credentials/Sprengelstraße - Wedding/1.png',
      name: 'Wohungssanierung - Wedding',
      text: ''
    }

  ];

  const getCredentialItems = () => credentials.map(item => (
    <CredentialsItem
      key={item.title}
      title={item.title}
      subtitle={item.subtitle}
      text={item.text}
      photos={item.photos}
    />
  ));

  const getReferences = () => reference.map((it) => (
    <ReferenceCard key={it.name} image={it.image} name={it.name} text={it.text} />
  ));

  const openPartners = () => {
    router.push('/partners')
  }

  return (
    <div className="relative">
      <div className="grid grid-cols-1 grid-rows-1">
        <img className="col-start-1 row-start-1 w-full object-cover" src="/home/intro.png"/>
        <h1 className="col-start-1 row-start-1 z-10 text-sm sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-7xl text-white self-center pl-7 sm:pl-12 md:pl-16 lg:pl-24 xl:pl-32 font-medium">
          Wir realisieren
          <br/>
          Ihre Projekte
        </h1>
      </div>
      <div className="grid grid-cols-5 gap-4 px-4 sm:px-8 md:px-12 lg:px-16 xl:px-20 py-4 sm:py-8 md:py-12 lg:py-16 xl:py-20">
          <img className="home-logo object-contain w-full h-auto" src="/brillux1.png" onClick={openPartners}/>
          <img className="home-logo object-contain w-full h-auto" src="/raab.png" onClick={openPartners}/>
          <img className="home-logo object-contain w-full h-auto" src="/loxam2.png" onClick={openPartners}/>
          <img className="home-logo object-contain w-full h-auto" src="/dammisolbw.png" onClick={openPartners}/>
          <img className="home-logo object-contain w-full h-auto" src="/bydbw.png" onClick={openPartners}/>

      </div>
      <div className="grid bg-primary-500 gap-7 sm:gap-12 md:gap-16 lg:gap-24 xl:gap-32 py-7 sm:py-12 md:py-16 lg:py-24 xl:py-32">
        <h1 className="text-sm sm:text-2xl lg:text-3xl lg:text-4xl xl:text-5xl text-white font-medium px-7 sm:px-12 md:px-16 lg:px-24 xl:px-32">Leistungen</h1>
        <Slider innerRef={ref}/>

      </div>
      <div className="grid bg-neutral-200 py-7 sm:py-12 md:py-16 lg:py-24 xl:py-32 px-7 sm:px-12 md:px-16 lg:px-24 xl:px-32 gap-7 sm:gap-12 md:gap-16 lg:gap-24 xl:gap-32">
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-primary-500 font-medium">Referenzen</h1>
        <div className="grid sm:grid-cols-2 gap-7 sm:gap-8 md:gap-9 lg:gap-10 xl:gap-12 justify-items-center items-center">
          {getReferences()}
        </div>
      </div>
      {/* <div className="grid gap-12 sm:gap-16 md:gap-20 lg:gap-24 xl:gap-32 px-10 sm:px-28 md:px-40 lg:px-52 xl:px-64 2xl:px-80 py-12 sm:py-16 md:py-20 lg:py-24 xl:py-32">
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl justify-self-center">Referenzen</h1>
        <div className="grid gap-32">
          {getCredentialItems()}
        </div>
      </div> */}
    </div>
  );
};

export default Home;
