import Link from 'next/link';

interface IReferenceCard {
  image: string;
  name: string;
  text: string;
}

export const ReferenceCard = (props: IReferenceCard) => {
  return (
    <Link href="credentials">
    <div className="w-full h-full grid overflow-hidden rounded-2xl cursor-pointer" >
      <img className="w-full h-32 sm:h-48 md:h-60 lg:h-72 xl:h-80 2xl:h-96 object-cover" src={props.image}/>
      <div className="grid py-2 sm:py-3 md:py-4 lg:py-6 xl:py-8 2xl:py-10 gap-1 sm:gap-2 md:gap-3 lg:gap-4 justify-items-center bg-primary-500">
        <h1 className="text-white text-xs sm:text-sm sm:text-md md:text-xl lg:text-2xl xl:text-3xl font-medium">
          {props.name}
        </h1>
        <h2 className="text-gray-300 text-xs sm:text-sm lg:text-md">
          {props.text}
        </h2>
      </div>
    </div>
  </Link>
  );
};

export default ReferenceCard;
