import React, { useState } from 'react';
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import { useRouter } from "next/router";
import { RefObject } from "react";

interface Image {
  key: number;
  src: string;
  url: string;
  name:string;
}

interface ISlider {
  innerRef: RefObject<AliceCarousel>
}

const images: Image[] = [
  {key: 0, src: '/services/badsanierung.png', url: '/services/badsanierung/', name:'Badsanierung'},
  {key: 1, src: '/services/trockenbau.png', url: '/services/innenausbau/', name:'Innenausbau'},
  {key: 2, src: '/services/wand.png', url: '/services/Wand und Bodenbeläge'.toLowerCase().replace(/\s+/g, ''), name:'Wand und Bodenbeläge'},
  {key: 3, src: '/services/Rohbau.png', url: '/services/sanierung/', name:'Sanierung'},
  {key: 4, src: '/services/aussenlagen.png', url: '/services/außenanlagen/', name:'Außenanlagen'}
];

const Slider = (props: ISlider) => {
  const router = useRouter();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const handleDragStart = (e: any) => e.preventDefault();
  const getImages = images.map((image, index) => (
    <img
      className={`slider-image w-[20vw] h-[33vw]`}
      key={image.url}
      id={image.url}
      src={image.src}
      alt={image.src}
      onDragStart={handleDragStart}
      onClick={() => router.push(image.url)}
    />
  ));

  const redirect = () => {
    if (!router.query.page) {
      router.push('/x');
    }
  };
  const handleSlideChange = (currentIndex: any) => {
    const slideIndex = currentIndex.item; // Extract the index from the currentIndex object
    console.log("Slide changed:", slideIndex);
    setCurrentImageIndex(prevIndex => {
      if (prevIndex !== slideIndex) {
        return slideIndex;
      }
      return prevIndex;
    });
  };
  return (
    <div className="relative">
    <div className="carousel w-[99vw] -translate-x-[3vw]">
      <AliceCarousel
        ref={props.innerRef}
        mouseTracking
        disableDotsControls
        disableButtonsControls
        responsive={{0: {items: 5}}}
        infinite
        items={getImages}
        onInitialized={redirect}
        onSlideChanged={handleSlideChange}
      />
    </div>
    <div className="flex justify-center items-center mt-20">
      <img
        className="justify-self-end rotate-180 home-arrow mr-2"
        src="/home/arrow.png"
        alt=""
        onClick={() => props.innerRef.current?.slidePrev()}
      />
      <div className="text-white mx-2">
        {images[(currentImageIndex + Math.floor(images.length / 2)) % images.length]?.name}
      </div>
      <img
        className="home-arrow ml-2"
        src="/home/arrow.png"
        alt=""
        onClick={() => props.innerRef.current?.slideNext()}
      />
    </div>

    </div>
  );
};

export default Slider;
